<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(validate)">
        <b-row>
          <b-col lg="12">
            <iq-card body-class="p-0">
              <template v-slot:body>
                <div class="iq-edit-list">
                  <tab-nav :pills="true" class="iq-edit-profile d-flex">
                    <tab-nav-items class="col-md-4 p-0" :active="true" href="#personal-information" title="Informacion Personal" />
                    <tab-nav-items class="col-md-4 p-0" :active="false" href="#manage-contact" title="Informacion de Contacto" />
                    <tab-nav-items class="col-md-4 p-0" :active="false" href="#emailandsms" title="Otros" />
                  </tab-nav>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="12">
            <div class="iq-edit-list-data">
              <tab-content id="pills-tabContent-2">
                <tab-content-item :active="true" id="personal-information" >
                  <iq-card>
                    <template v-slot:body>
                        <b-form-group class="row align-items-center">
                          <b-col md="12">
                            <div class="profile-img-edit">
                              <b-img :src="user.profile_image" class="profile-pic height-150 width-150" alt="profile-pic"/>
                              <input type="hidden" v-model="user.profile_image">
                              <div class="p-image">
                                <div class="position-relative">
                                  <input
                                    type="file"
                                    ref="cedulaTrabajador"
                                    accept="image/png"
                                    :disabled="loading"
                                    class="ri-pencil-line upload-button"
                                    id="archivoCedulaConyuge"
                                    data-validate="Anexe el poder diligenciado"
                                  >
                                  <button :disabled="loading" @click="agregar">Subirr Archivo</button>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-form-group>
                        <b-row align-v="center">
                          <b-form-group class="col-md-6" label="Nombre Completo" label-for="name">
                            <ValidationProvider name="Nombre Completo" rules="required" v-slot="{ errors }">
                              <b-form-input v-model="user.name" type="text" placeholder="Nombre Completo" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                          <b-form-group class="col-md-6" label="Documento" label-for="uname">
                            <ValidationProvider name="User name" v-slot="{ errors }">
                              <b-form-input v-model="user.identification" type="text" placeholder="Documento" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                          <b-form-group class="col-sm-6" label="Genero:" label-class="d-block">
                            <b-form-radio inline v-model="user.gender" value="male">Male</b-form-radio>
                            <b-form-radio inline v-model="user.gender" value="female">Female</b-form-radio>
                          </b-form-group>
                          <b-form-group class="col-sm-6" label="Fecha de Nacimiento:" label-for="dob">
                            <b-form-input type="date" v-model="user.dob" id="dob"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-md-6" label="Celular" label-for="name">
                            <ValidationProvider name="celular" v-slot="{ errors }">
                              <b-form-input v-model="user.phonePrimary" type="text" placeholder="celular" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                          <b-form-group class="col-md-6" label="Correo" label-for="name">
                            <ValidationProvider name="correo" v-slot="{ errors }">
                              <b-form-input v-model="user.email" type="text" placeholder="email" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                          <b-form-group class="col-sm-6" label-for="exampleFormControlSelect3" label="Pais:">
                            <b-form-input v-model="user.address.country" type="text" id="exampleFormControlSelect3">
                            </b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6" label-for="exampleFormControlSelect4" label="Departamento:">
                            <b-form-input v-model="user.address.department" type="text" id="exampleFormControlSelect4">
                            </b-form-input>
                          </b-form-group>
                          <b-form-group class="col-md-6" label="Ciudad:" label-for="uname">
                            <b-form-input v-model="user.address.city" type="text" placeholder="City"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6" label-for="barrio" label="Barrio:">
                            <b-form-input v-model="user.address.neighborhood" type="text" id="exampleFormBarrio">
                            </b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6" label-for="sector" label="Sector:">
                            <b-form-input v-model="user.address.sector" type="text" id="exampleFormSector">
                            </b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-12" label="Direccion:">
                            <b-form-textarea name="address" v-model="user.address.address" style="line-height: 22px;" rows="5">
                            </b-form-textarea>
                          </b-form-group>
                        </b-row>
                        <b-button type="submit" variant="primary" class="mr-2">Guardar</b-button>
                        <b-button @click="volver" variant="none" class="iq-bg-danger">Cancelar</b-button>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="emailandsms">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Habilidades</h4>
                    </template>
                    <template v-slot:body>
                      <div>
                        <b-form-group v-slot="{ ariaDescribedby }">
                          <b-form-checkbox-group
                            id="checkbox-group-1"
                            v-model="selected"
                            :options="options"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                            switches
                            stacked
                          ></b-form-checkbox-group>
                        </b-form-group>
                        <div>Selected: <strong>{{ selected }}</strong></div>
                      </div>
                      <b-button type="submit" variant="primary" class="mr-2">Guardar</b-button>
                      <b-button @click="volver" variant="none" class="iq-bg-danger">Cancelar</b-button>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="manage-contact" >
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Documentos</h4>
                    </template>
                    <template v-slot:body>
                    </template>
                  </iq-card>
                </tab-content-item>
              </tab-content>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { db } from '../../config/firebase'
import firebase from 'firebase'

export default {
  name: 'ProfileEditCurrent',
  mounted () {
    core.index()
    this.getData()
  },
  firestore () {
    return {
      // user: db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/').doc(this.$route.params.idDoc)
    }
  },
  data () {
    return {
      file: undefined,
      loading: false,
      user: {
        address: {
          address: '',
          city: '',
          country: '',
          department: '',
          neighborhood: '',
          sector: ''
        },
        type: '',
        name: '',
        nameAdmin: '',
        email: '',
        url: '',
        phonePrimary: '',
        identificationObject: {
          number: '',
          type: '',
          dv: ''
        },
        profile_image: ''
      },
      currentPassword: '',
      selected: [], // Array reference
      options: [
        { value: 'conductor', text: 'Conductor' },
        { value: 'sonido', text: 'Operario de Sonido' },
        { value: 'registro', text: 'Registro de Asistencia' },
        { value: 'virtual', text: 'Asamblea Virtual' },
        { value: 'soporte', text: 'Soporte' },
        { value: 'acta', text: 'Actas' },
        { value: 'conexion', text: 'Conexion' }
      ],
      conductor: false,
      sonido: false,
      registro: false,
      virtual: false,
      soporte: false,
      acta: false,
      conexion: false
    }
  },
  methods: {
    async validate () {
      this.conductor = false
      this.sonido = false
      this.registro = false
      this.virtual = false
      this.soporte = false
      this.acta = false
      this.conexion = false
      for (let index = 0; index < this.selected.length; index++) {
        switch (this.selected[index]) {
          case 'conductor':
            this.conductor = true
            break
          case 'sonido':
            this.sonido = true
            break
          case 'registro':
            this.registro = true
            break
          case 'virtual':
            this.virtual = true
            break
          case 'soporte':
            this.soporte = true
            break
          case 'acta':
            this.acta = true
            break
          case 'conexion':
            this.conexion = true
            break
        }
      }
      var newData = {
        docId: this.user.docId,
        email: this.user.email,
        id: this.user.id,
        index: this.user.index,
        name: this.user.name,
        nit: this.user.nit,
        phone: this.user.phone,
        staff: {
          conductor: this.conductor,
          sonido: this.sonido,
          registro: this.registro,
          virtual: this.virtual,
          soporte: this.soporte,
          acta: this.acta,
          conexion: this.conexion
        },
        type: 'provider'
      }
      await db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
        .doc(`contactsIndex${this.user.index}`)
        .update({
          [this.user.docId]: newData
        }).then((indexDoc) => {
          console.log('Actualizado en el index')
          this.makeToast('success', 'Contacto actualizado en Sistevot')
          db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/').doc(this.user.docId).update(newData)
            .then(() => {
              console.log('exito')
              this.$router.replace('/admin-dashboard/provider')
            })
        }).catch((error) => {
          console.log('Error actualizando el indice', error)
          this.makeToast('danger', 'Error al actualizar contacto en Sistevot. ', error)
        })
    },
    getData () {
      db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/')
        .doc(this.$route.params.idDoc)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.user = doc.data()
            if (doc.data().staff.conductor === true) { this.selected.push('conductor') }
            if (doc.data().staff.sonido === true) { this.selected.push('sonido') }
            if (doc.data().staff.registro === true) { this.selected.push('registro') }
            if (doc.data().staff.virtual === true) { this.selected.push('virtual') }
            if (doc.data().staff.soporte === true) { this.selected.push('soporte') }
            if (doc.data().staff.acta === true) { this.selected.push('acta') }
            if (doc.data().staff.conexion === true) { this.selected.push('conexion') }
          }
        })
    },
    onSubmit () {
      // Update user
      console.log(this.$firestoreRefs.user)
      console.log('Informacion del User', this.user)
      // this.$firestoreRefs.user.update(this.user).then(() => {
      //   core.showSnackbar('success', 'User has been added successfully.')
      // })
      db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/').doc(this.$route.params.idDoc).update(this.user)
        .then(() => {
          console.log('exito')
        })

      this.$router.replace('/admin-dashboard/provider')
    },
    previewImage: function (event) {
      const input = event.target

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    changePassword: function () {
    },
    prueba () {
      console.log(this.user)
    },
    async agregar () {
      console.log('La constante files', this.$refs.cedulaTrabajador)
      try {
        const { files } = this.$refs.cedulaTrabajador
        this.loading = true
        const file = files[0]
        if (file) {
          const isPdf = file.type === 'image/png'
          if (isPdf) {
            const response = await firebase
              .storage()
              .ref(`/campus/Vs2FkUx38u3W8IDuABNF/contacts/${this.$route.params.idDoc}/Foto-de-perfil`)
              .put(file)
            const url = await response.ref.getDownloadURL()
            this.user.profile_image = await url
            db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
              .doc(this.user.docId)
              .update(this.user)
              .then((updatedDoc) => {
                console.log('Contacto actualizado', updatedDoc)
                db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
                  .doc(`contactsIndex${this.user.index}`)
                  .update({
                    [this.user.docId]: this.user
                  })
                  .then((updatedIndex) => {
                    console.log('Actualizado en el Indice', updatedIndex)
                  })
              })
            // console.log('archivo disponible en ', url)
            // this.downloadUrl = url
            // this.poder.downloadUrl = url
          } else {
            console.log('Archivo no valido', file)
          }
        } else {
          console.log('falta el archivo', file)
        }
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    volver () {
      this.$router.replace('/admin-dashboard/provider')
    },
    makeToast (variant = null, message) {
      var title1 = ''
      if (variant === 'success') {
        title1 = 'Proceso exitoso'
      } else if (variant === 'danger') {
        title1 = 'Proceso fallido'
      }
      this.$bvToast.toast(message, {
        title: title1,
        variant: variant,
        solid: true,
        autoHideDelay: 10000
      })
    }
  }
}
</script>
