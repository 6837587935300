<template>
  <b-container fluid>
    <!-- Inicio Modal de disponibilidad -->
    <b-modal v-model="scheduleModal" :title="'Disponibilidad Semanal ' + startMonth + ' ' + date.weekStart.day + ' - ' + endMonth + ' ' +  date.weekEnd.day + ', '+ weekDate.year" size="xl">
      <b-overlay>
        <b-container fluid>
          <b-row>
            <b-col md="12">
              <iq-card>
                <template v-slot:headerTitle>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12" class="table-responsive">
                      <b-table bordered hover :items="dates" :fields="columns">
                        <template v-slot:cell(start)="data">
                          <div v-if="data.item.available">
                            <!-- <span v-if="!data.item.editable">{{ formatAMPM(data.item.start) }}</span> -->
                            <span v-if="!data.item.editable">{{ formatAMPM(data.item.start) }}</span>
                            <b-time v-else v-model="data.item.start" locale="en"></b-time>
                            <b-button variant="ml-1 mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                            <b-button variant="iq-bg-success ml-1 mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                          </div>
                          <div v-else>
                            <span v-if="!data.item.editable" class="text-danger">Sin asignar</span>
                            <b-time v-else v-model="data.item.start" locale="en"></b-time>
                            <b-button variant=" iq-bg-warning ml-1 mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                            <b-button variant=" iq-bg-success ml-1 mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                          </div>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
      <template #modal-footer>
        <b-button variant="danger" size="sm" class="float-right" @click="schedulexModal=false">
          Cancelar
        </b-button>
        <b-button variant="success" size="sm" class="float-right" @click="saveAvailability">
          Guardar
        </b-button>
      </template>
    </b-modal>
    <!-- Fin Modal de disponibilidad -->
    <!-- Inicio Modal de confirmación de hora -->
    <b-modal v-model="confirmHourModal" centered title="Confirmación">
      <p>¿Está seguro que desea asignar <span class="font-weight-bold">{{ this.currentService.data.endTime }}</span> como hora final de la asamblea? <span v-if="passDay">Esto significa que la asamblea finalizó al día siguiente del día inicial</span></p>
      <ul>
        <li><span class="font-weight-bold">Hora de inicio: </span>{{ this.currentService.data.startTime }}</li>
        <li><span class="font-weight-bold">Hora de fin: </span>{{ this.currentService.data.endTime }}</li>
      </ul>
      <template #modal-footer>
        <b-button variant="danger" size="sm" class="float-right" @click="confirmHourModal=false">
          Cancelarr
        </b-button>
        <b-button variant="success" size="sm" class="float-right" @click="updateAssemblie">
          Sí
        </b-button>
      </template>
    </b-modal>
    <!-- Fin Modal de confirmación de hora -->
    <!-- Modal de  info de asamblea-->
    <b-modal v-model="assemblieInfo" size="xl" title="Información de la asamblea">
      <b-overlay :show="assemblieOverlay" rounded="sm">
        <b-row>
          <b-col v-for="input in inputs" :key="input.index" cols="12" sm="6" class="mb-3">
            <label class="ml-2" for="input-small">{{input.name}}</label>
            <!-- Si es Label -->
            <b-input-group v-if="input.type === 'label'" size="sm">
              <template #prepend>
                <b-input-group-text >
                  <i :class="input.iconClass"></i>
                </b-input-group-text>
              </template>
              <span>{{ currentAssemblie[input.dataModel]}}</span>
              <!-- <b-form-input v-model="currentAssemblie[input.dataModel]" :id="input.name" :disabled="input.disabled"></b-form-input> -->
            </b-input-group>
            <!-- Si es Link -->
            <b-input-group v-if="input.type === 'link'" size="sm">
              <template #prepend>
                <b-input-group-text >
                  <i :class="input.iconClass"></i>
                </b-input-group-text>
              </template>
              <a :href="currentAssemblie[input.dataModel]" target="_blank">{{ input.message }}</a>
              <!-- <b-form-input v-model="currentAssemblie[input.dataModel]" :id="input.name" :disabled="input.disabled"></b-form-input> -->
            </b-input-group>
            <!-- Si es hora -->
            <b-input-group v-if="input.type === 'time'" size="sm">
              <template  v-if="!editHour" #prepend>
                <b-input-group-text >
                  <i :class="input.iconClass"></i>
                </b-input-group-text>
              </template>
              <b-time v-if="editHour" v-model="currentAssemblie[input.dataModel]" locale="en"></b-time>
              <span v-if="!editHour" class="mr-2">{{ currentAssemblie[input.dataModel] }}</span>
              <span v-if="!editHour && currentAssemblie.canEditHour.canEdit" class="ml-2 text-warning hoverSpan" @click="editHour=!editHour">editar</span>
              <span v-else class="ml-2 text-success hoverSpan" @click="editHour=!editHour">OK</span>
              <!-- <b-form-input v-else v-model="currentAssemblie[input.dataModel]" :id="input.name" :disabled="input.disabled"></b-form-input> -->
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" class="mb-3">
            <label class="ml-2" for="input-small">Hora de inicio</label>
            <b-input-group size="sm">
              <template #prepend>
                <b-input-group-text >
                  <i class="far fa-clock"></i>
                </b-input-group-text>
              </template>
              <span>{{ currentService.data.startTime }}</span>
              <!-- <b-form-input v-model="currentAssemblie[input.dataModel]" :id="input.name" :disabled="input.disabled"></b-form-input> -->
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" class="mb-3">
            <label class="ml-2" for="input-small">Hora de fin:</label>
            <b-input-group size="sm">
              <template  v-if="!currentService.data.editHour" #prepend>
                <b-input-group-text >
                  <i class="fas fa-clock"></i>
                </b-input-group-text>
              </template>
              <b-time v-if="currentService.data.editHour" v-model="currentService.data.endTime" locale="en"></b-time>
              <span v-if="!currentService.data.editHour" class="mr-2">{{ currentService.data.endTime }}</span>
              <span v-if="!currentService.data.editHour" class="ml-2 text-warning hoverSpan" @click="currentService.data.editHour=!currentService.data.editHour">editar</span>
              <span v-else class="ml-2 text-success hoverSpan" @click="currentService.data.editHour=!currentService.data.editHour">OK</span>
              <!-- <b-form-input v-else v-model="currentAssemblie[input.dataModel]" :id="input.name" :disabled="input.disabled"></b-form-input> -->
            </b-input-group>
          </b-col>
        </b-row>
      </b-overlay>
      <template #modal-footer>
        <b-button :disabled="assemblieOverlay" variant="danger" size="sm" class="float-right" @click="clear()">
          Cerrar
        </b-button>
        <b-button v-if="currentAssemblie.canEditHour.canEdit" :disabled="assemblieOverlay" @click="checkHours" variant="success" size="sm" class="float-right">
          Guardar
        </b-button>
      </template>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <iq-card body-class=" profile-page p-0">
          <template v-slot:body>
            <div class="profile-header">
              <div class="profile-info p-4">
                <b-row>
                  <b-col md="6" sm="12">
                    <div class="user-detail pl-5">
                      <div class="d-flex flex-wrap align-items-center">
                        <div class="profile-img pr-4">
                          <b-img v-bind:src="user.profile_image" alt="profile-img" fluid class="avatar-130" />
                        </div>
                        <div class="profile-detail d-flex align-items-center">
                          <h4>{{user.name}}</h4>
                          <p class="m-0 pl-3"> {{user.role}} </p>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="6" sm="12">
                    <tab-nav :pills="true" id="pills-tab" class="profile-feed-items d-flex justify-content-end">
                      <tab-nav-items :active="true" id="pills-calendar-tab" href="#calendar" ariaControls="pills-home" role="tab" :ariaSelected="true" title="Calendario" />
                      <tab-nav-items :active="false" id="pills-docs-tab" href="#profile-docs" ariaControls="pills-docs" role="tab" :ariaSelected="false" title="Documentos" />
                      <tab-nav-items :active="false" id="pills-profile-tab" href="#profile" ariaControls="pills-profile" role="tab" :ariaSelected="false" title="Perfil" />
                      <tab-nav-items :active="false" id="pills-billing-tab" href="#billing" ariaControls="pills-billing" role="tab" :ariaSelected="false" title="CC" method="getServices" @getServices="getServices"/>
                    </tab-nav>
                  </b-col>
                </b-row>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="12">
        <b-row>
          <b-col lg="3" class="profile-left">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Informacion Basica</h4>
              </template>
              <template v-slot:body>
                <div class="about-info m-0 p-0">
                  <b-row>
                    <b-col cols="12"><p>{{user.text}}</p></b-col>
                    <b-col cols="3">Doc: </b-col>
                    <b-col cols="9">{{user.identificationObject.number}}</b-col>
                    <b-col cols="3">Email : </b-col>
                    <b-col cols="9">{{user.email}}</b-col>
                    <b-col cols="3">Phone:</b-col>
                    <b-col cols="9">{{user.phone}}</b-col>
                    <b-col cols="3">Direccion:</b-col>
                    <b-col cols="9">{{user.address.address}}</b-col>
                  </b-row>
                </div>
              </template>
            </iq-card>
            <iq-card>
              <!-- <template v-slot:headerAction>
                <i class="far fa-edit fa-xs"></i>
              </template> -->
              <template v-slot:headerTitle>
                <h4 class="card-title">Disponibilidad Semanal</h4>
                <i class="far fa-edit fa-xs hoverSpan" @click="scheduleModal = true">
                  <span class="font-size-12 mb-0 ml-1 hoverSpan" style="font-family: 'Muli', sans-serif;" variant="success">Editar</span>
                </i>
              </template>
              <template v-slot:body>
                <ul class="m-0 p-0">
                  <li v-for="(day,index) in dates" :key="index"  :class="{ 'mb-2': index === 0 }"   >
                    <div>
                      <p class="news-detail mb-0">{{ day.date }}</p>
                      <p v-if="day.available || day.start !== ''" class="font-size-12">Desde: {{ formatAMPM(userAvailability[index + 1].start) }}</p>
                      <p class="text-danger" v-else>Sin Asignar</p>
                    </div>
                  </li>
                </ul>
              </template>
            </iq-card>
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Habilidades</h4>
              </template>
              <template v-slot:body>
                <ul class="m-0 p-0">
                  <li class="d-flex" v-for="(list,index) in user.staff" :key="index"  :class="{ 'mb-2': index === 0 }"   >
                    <div v-if="list === true" class="news-icon" :class="{ 'mb-0': index==list.length-1 }"><i class="ri-chat-4-fill"></i></div>
                    <p v-if="list === true" class="news-detail mb-0" v-html="index"></p>
                  </li>
                </ul>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="9" class="profile-center">
            <tab-content id="pills-tabcontent">
              <tab-content-item :active="true" id="calendar" aria-labelled-by="pills-feed-tab">
                <iq-card>
                  <b-container fluid>
                    <b-sidebar id="sidebar-1" title="Filtros" right shadow>
                      <div class="px-3 py-2">
                        <b-form-checkbox class="switch-presencial" v-model="filters.presencial" switch size="sm"><i v-if="filters.presencial" class="fas fa-circle fa-xs switch-presencial"></i><i v-else class="far fa-circle fa-xs switch-presencial"></i> Presencial</b-form-checkbox>
                        <b-form-checkbox class="switch-virtual" v-model="filters.virtual" switch size="sm"><i v-if="filters.virtual" class="fas fa-circle fa-xs switch-virtual"></i><i v-else class="far fa-circle fa-xs switch-virtual"></i> Virtual</b-form-checkbox>
                        <b-form-checkbox class="switch-mixta" v-model="filters.mixta" switch size="sm"><i v-if="filters.mixta" class="fas fa-circle fa-xs switch-mixta"></i><i v-else class="far fa-circle fa-xs switch-mixta"></i> Mixta</b-form-checkbox>
                        <b-form-checkbox class="switch-cotizacion" v-model="filters.cotizacion" switch size="sm"><i v-if="filters.cotizacion" class="fas fa-circle fa-xs switch-cotizacion"></i><i v-else class="far fa-circle fa-xs switch-cotizacion"></i> Cotizaciones</b-form-checkbox>
                        <b-button @click="getWeekDates(weekDate)">Aplicar Filtros</b-button>
                      </div>
                      <div class="fixed-bottom pl-2">
                        <p class="text-primary mb-0">
                          <i class="fas fa-chalkboard-teacher fa-xs mr-1"></i>
                          <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">Virtual</a>
                          <i class="fas fa-volume-up fa-xs mr-1"></i>
                          <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">Presencial</a>
                          <i class="fas fa-sticky-note fa-xs mr-1"></i>
                          <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">Cotización</a>
                        </p>
                      </div>
                    </b-sidebar>
                    <b-row>
                      <b-col md="12">
                        <b-row>
                          <b-col cols="12 text-right mb-4">
                          </b-col>
                        </b-row>
                        <b-row align-v="baseline">
                          <b-col align-self="start" class="text-left pl-0" cols="3">
                          <b-button size="sm" class="mb-1" block @click="addAssemblie()">Añadir Asamblea <i class="fas fa-plus-circle fa-xs"></i></b-button>
                          </b-col>
                          <b-col cols="6" class="text-center">
                            <b-button-group class="mb-1">
                              <b-button size="sm" @click="changeWeek('prev')"><i class="fas fa-chevron-left"></i></b-button>
                              <b-button size="sm" class="btn-date text-dark" variant="outline-secondary">{{this.startMonth}} {{this.date.weekStart.day}} - {{this.endMonth}} {{this.date.weekEnd.day}}, {{this.weekDate.year}}</b-button>
                              <b-button size="sm" @click="changeWeek('next')"><i class="fas fa-chevron-right"></i></b-button>
                            </b-button-group>
                          </b-col>
                          <b-col align-self="end" cols="3" class="text-right pr-0">
                            <b-button variant="outline-secondary" class="mb-1 mr-1" size="sm" v-b-toggle.sidebar-1>Filtros <i class="fas fa-filter"></i></b-button>
                          </b-col>
                        </b-row>
                        <div class="row">
                          <div class="col-md-12 track pl-0 pr-0" style="min-height:50vh">
                            <iq-card id="foo" bodyClass="task-body" headerClass="header-small" class="bg-transparent shadow-none w-15" v-for="(item, index) in boards" :key="index">
                              <template v-slot:headerTitle>
                                <a class="text-white font-size-18 mr-1">{{item.date}}</a>
                                <a class="text-white font-size-12">{{ item.title }}</a>
                              </template>
                              <template v-slot:body>
                                <i  v-for="(element, index) in item.list"
                                    :key="element.name +'-'+ index"
                                    @click="viewAssemblieInfo(element)"
                                    class="hoverSpan"
                                >
                                  <iq-card
                                    bodyClass="task-card"
                                    headerClass="task-card-title"
                                    class="mb-2"
                                  >
                                    <template v-slot:headerTitle>
                                      <a class="card-title text-muted">{{ element.name }}</a>
                                    </template>
                                    <template v-slot:body>
                                      <div>
                                        <p class="font-size-11 mb-0">
                                          <i class="fas fa-users fa-xs mr-1"></i>
                                          <span class="mr-1">{{ element.users }}</span>
                                          <i class="fas fa-clock fa-xs mr-1"></i>
                                          <span>{{ formatAMPM(element.hour) }}</span>
                                        </p>
                                        <p style="min-height: 43px" class="font-size-12">
                                          {{element.admin}}
                                        </p>
                                        <div class="d-flex justify-content-between">
                                        </div>
                                      </div>
                                      <b-progress style="height: 4px" class="mt-2" :max="100" show-value>
                                        <b-progress-bar :value="100" class="iq-border-radius-10" :variant="element.type" label=" " />
                                      </b-progress>
                                    </template>
                                  </iq-card>
                                </i>
                              </template>
                            </iq-card>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </iq-card>
              </tab-content-item>
              <tab-content-item :active="false" id="profile-docs" aria-labelled-by="pills-activity-tab">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Documentos personales</h4>
                  </template>
                  <template v-slot:body>
                    <b-row class="mt-2">
                      <b-col>
                        <h6>Hoja de vida</h6>
                        <i class="fas fa-file-csv mr-2"></i>
                        <a v-if="hasCV.status" class="text-success" :href="hasCV.url" target="blank_">{{ hasCV.name }} </a>
                        <a v-else class="text-danger">Pendiente </a>
                        <i v-if="!hasCV.status" class="fas fa-ban fa-xs text-danger"></i>
                        <i v-else class="fas fa-check fa-xs text-success"></i>
                        <span v-if="hasCV.status" class="ml-2 hoverSpan" @click="resetDocument('CV', 'edit')">editar</span>
                        <span v-else class="ml-2 hoverSpan" @click="resetDocument('CV', 'cancel')">cancelar</span>
                        <div v-if="!hasCV.status" class="input-group mb-3">
                          <input type="file" ref="CV" accept="application/pdf" :disabled="loading" class="form-control custom-fi">
                        </div>
                        <button v-if="!hasCV.status" class="btn-secondary float-right" :disabled="loading" @click="saveFile('CV', 'Hoja de vida')">Subir Archivo</button>
                      </b-col>
                      <b-col>
                        <h6>Cédula</h6>
                        <i class="fas fa-id-card mr-2"></i>
                        <a v-if="hasCC.status" class="text-success" :href="hasCC.url" target="blank_">{{ hasCC.name }} </a>
                        <a v-else class="text-danger">Pendiente </a>
                        <i v-if="!hasCC.status" class="fas fa-ban fa-xs text-danger"></i>
                        <i v-else class="fas fa-check fa-xs text-success"></i>
                        <span v-if="hasCC.status" class="ml-2 hoverSpan" @click="resetDocument('CC', 'edit')">editar</span>
                        <span v-else class="ml-2 hoverSpan" @click="resetDocument('CC', 'cancel')">cancelar</span>
                        <div v-if="!hasCC.status" class="input-group mb-3">
                          <input type="file" ref="CC" accept="application/pdf" :disabled="loading" class="form-control custom-fi">
                        </div>
                        <button v-if="!hasCC.status" class="btn-secondary float-right" :disabled="loading" @click="saveFile('CC', 'Cédula')">Subir Archivo</button>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col>
                        <h6>Certificado EPS</h6>
                        <i class="fas fa-medkit mr-2"></i>
                        <a v-if="hasEPS.status" class="text-success" :href="hasEPS.url" target="blank_">{{ hasEPS.name }} </a>
                        <a v-else class="text-danger">Pendiente </a>
                        <i v-if="!hasEPS.status" class="fas fa-ban fa-xs text-danger"></i>
                        <i v-else class="fas fa-check fa-xs text-success"></i>
                        <span v-if="hasEPS.status" class="ml-2 hoverSpan" @click="resetDocument('EPS', 'edit')">editar</span>
                        <span v-else class="ml-2 hoverSpan" @click="resetDocument('EPS', 'cancel')">cancelar</span>
                        <div v-if="!hasEPS.status" class="input-group mb-3">
                          <input type="file" ref="EPS" accept="application/pdf" :disabled="loading" class="form-control custom-fi">
                        </div>
                        <button v-if="!hasEPS.status" class="btn-secondary float-right" :disabled="loading" @click="saveFile('EPS', 'Certificado EPS')">Subir Archivo</button>
                      </b-col>
                      <b-col>
                        <h6>Certificado de pensión</h6>
                        <i class="fas fa-university mr-2"></i>
                        <a v-if="hasCP.status" class="text-success" :href="hasCP.url" target="blank_">{{ hasCP.name }} </a>
                        <a v-else class="text-danger">Pendiente </a>
                        <i v-if="!hasCP.status" class="fas fa-ban fa-xs text-danger"></i>
                        <i v-else class="fas fa-check fa-xs text-success"></i>
                        <span v-if="hasCP.status" class="ml-2 hoverSpan" @click="resetDocument('CP', 'edit')">editar</span>
                        <span v-else class="ml-2 hoverSpan" @click="resetDocument('CP', 'cancel')">cancelar</span>
                        <div v-if="!hasCP.status" class="input-group mb-3">
                          <input type="file" ref="CP" accept="application/pdf" :disabled="loading" class="form-control custom-fi">
                        </div>
                        <button v-if="!hasCP.status" class="btn-secondary float-right" :disabled="loading" @click="saveFile('CP', 'Certificado pensión')">Subir Archivo</button>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col>
                        <h6>Certificado bancario</h6>
                        <i class="fas fa-money-check-alt mr-2"></i>
                        <a v-if="hasCB.status" class="text-success" :href="hasCB.url" target="blank_">{{ hasCB.name }} </a>
                        <a v-else class="text-danger">Pendiente </a>
                        <i v-if="!hasCB.status" class="fas fa-ban fa-xs text-danger"></i>
                        <i v-else class="fas fa-check fa-xs text-success"></i>
                        <span v-if="hasCB.status" class="ml-2 hoverSpan" @click="resetDocument('CB', 'edit')">editar</span>
                        <span v-else class="ml-2 hoverSpan" @click="resetDocument('CB', 'cancel')">cancelar</span>
                        <div v-if="!hasCB.status" class="input-group mb-3">
                          <input type="file" ref="CB" accept="application/pdf" :disabled="loading" class="form-control custom-fi">
                        </div>
                        <button v-if="!hasCB.status" class="btn-secondary float-right" :disabled="loading" @click="saveFile('CB', 'Certificado bancario')">Subir Archivo</button>
                      </b-col>
                      <b-col>
                        <h6>RUT</h6>
                        <i class="fas fa-passport mr-2"></i>
                        <a v-if="hasRUT.status" class="text-success" :href="hasRUT.url" target="blank_">{{ hasRUT.name }} </a>
                        <a v-else class="text-danger">Pendiente </a>
                        <i v-if="!hasRUT.status" class="fas fa-ban fa-xs text-danger"></i>
                        <i v-else class="fas fa-check fa-xs text-success"></i>
                        <span v-if="hasRUT.status" class="ml-2 hoverSpan" @click="resetDocument('RUT', 'edit')">editar</span>
                        <span v-else class="ml-2 hoverSpan" @click="resetDocument('RUT', 'cancel')">cancelar</span>
                        <div v-if="!hasRUT.status" class="input-group mb-3">
                          <input type="file" ref="RUT" accept="application/pdf" :disabled="loading" class="form-control custom-fi">
                        </div>
                        <button v-if="!hasRUT.status" class="btn-secondary float-right" :disabled="loading" @click="saveFile('RUT', 'RUT')">Subir Archivo</button>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </tab-content-item>
              <tab-content-item :active="false" id="profile" aria-labelled-by="pills-personalInfo-tab">
                <profile-edit-current></profile-edit-current>
              </tab-content-item>
              <tab-content-item :active="false" id="billing" aria-labelled-by="pills-billing-tab">
                <h4 class="card-title">Historial de servicios</h4>
                <h6 v-if="serviceList.length < 1">Aún no tienes ningún servicio aprobado</h6>
                <iq-card  v-for="( service,index ) in serviceList" :key="index" class="iq-card-block iq-card-stretch" className="listing-card">
                  <template v-slot:body>
                    <b-row>
                      <b-col cols="2" sm="1">
                        <div :class="'profile-icon bg-' + service.color"><span><i :class="service.icon"></i></span></div>
                      </b-col>
                      <b-col cols="10" sm="5">
                        <div class="media-support-info ml-3">
                            <h6 v-if="service.type === 'virtual'">{{ service.title }}</h6>
                            <p class="mb-0  fontsize-sm">{{ service.date }}, {{ service.start }} - {{ service.end }}</p>
                            <label v-if="service.type === 'virtual'" for="input-medium" @click="service.showServices = !service.showServices">
                              Ver detalle
                              <i v-if="!service.showServices" class="ri-arrow-drop-down-line ml-1"></i>
                              <i v-else class="ri-arrow-drop-up-line ml-1"></i>
                            </label>
                        </div>
                      </b-col>
                      <b-col cols="4" sm="2" class="mt-2">
                        <div class="media-support-amount ml-3">
                          <h6>{{ service.totalHours }} horas</h6>
                        </div>
                      </b-col>
                      <b-col cols="4" sm="2" class="mt-2">
                        <div class="media-support-amount ml-3">
                            <h6 class="text-success">{{ currencyFormatterCop(service.amount) }}</h6>
                        </div>
                      </b-col>
                      <b-col cols="4" sm="2" class="mt-2">
                        <div class="media-support-amount ml-3">
                          <h6 class="text-danger">{{ service.status }}</h6>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-if="service.type === 'virtual'">
                      <b-col cols="6" sm="1"></b-col>
                      <b-col>
                        <b-collapse v-model="service.showServices">
                          <ul class="bullet-list">
                            <li v-for="(detail) in service.details" :key="detail.name">
                              <span class="font-weight-bold">{{ detail.title }}</span>
                              <span class="ml-2">{{ detail.start }} - {{ detail.end }}</span>
                            </li>
                          </ul>
                        </b-collapse>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </tab-content-item>
            </tab-content>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { db } from '@/config/firebase'
import firebase from 'firebase'
import ProfileEditCurrent from '../Admin/ProfileEditCurrent.vue'
const { DateTime } = require('luxon')

function formatNumber (number) {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}
export default {
  name: 'staffProfile',
  components: {
    ProfileEditCurrent
  },
  beforeMount () {
  },
  firestore () {
    return {
      // user: db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/').doc(this.$route.params.idDoc)
      // user: db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/').doc(`${this.$route.params.idDoc}`)
    }
  },
  data () {
    return {

      columns: [
        { label: 'Fecha', key: 'date', class: 'text-left' },
        { label: 'Hora inicio', key: 'start', class: 'text-left' }
      ],
      file1: null,
      hasCV: {
        status: false,
        name: '',
        url: ''
      },
      hasCC: {
        status: false,
        name: '',
        url: ''
      },
      hasEPS: {
        status: false,
        name: '',
        url: ''
      },
      hasCP: {
        status: false,
        name: '',
        url: ''
      },
      hasCB: {
        status: false,
        name: '',
        url: ''
      },
      hasRUT: {
        status: false,
        name: '',
        url: ''
      },
      file: undefined,
      loading: false,
      dates: [],
      scheduleModal: false,
      rowDataCard: null,
      user: {
        identificationObject: {},
        address: {}
      },
      value: null,
      filters: {
        presencial: true,
        virtual: true,
        mixta: true,
        cotizacion: true
      },
      weekDate: DateTime.now().c,
      date: {
        day: 0,
        weekStart: {},
        weekEnd: {},
        today: {
          day: 0,
          month: 0,
          year: 0
        },
        weekOfYear: 0
      },
      boards: [
        {
          id: 1,
          title: 'Lunes',
          date: '',
          color: 'bg-primary',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 2,
          title: 'Martes',
          date: '',
          color: 'bg-success',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 3,
          title: 'Miércoles',
          date: '',
          color: 'bg-info',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: [
          ]
        },
        {
          id: 4,
          title: 'Jueves',
          date: '',
          color: 'bg-warning',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 5,
          title: 'Viernes',
          date: '',
          color: 'bg-danger',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 6,
          title: 'Sábado',
          date: '',
          color: 'bg-danger',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 7,
          title: 'Domingo',
          date: '',
          color: 'bg-danger',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        }
      ],
      userAvailability: {
        1: {
          available: false,
          start: '00:00:00'
        },
        2: {
          available: false,
          start: '00:00:00'
        },
        3: {
          available: false,
          start: '00:00:00'
        },
        4: {
          available: false,
          start: '00:00:00'
        },
        5: {
          available: false,
          start: '00:00:00'
        },
        6: {
          available: false,
          start: '00:00:00'
        },
        7: {
          available: false,
          start: '00:00:00'
        }
      },
      months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
      prueba: {},
      operatorTypes: ['operarioActaSeleccionado', 'operarioConexionSeleccionado', 'operarioRegistroSeleccionado', 'operarioSonidoSeleccionado', 'operarioSoporteSeleccionado', 'operarioVirtualSeleccionado'],
      // Data para el simple modal
      assemblieInfo: false,
      assemblieOverlay: false,
      currentAssemblie: {
        canEditHour: {
          canEdit: false
        }
      },
      currentList: {},
      currentService: {
        data: {},
        type: '',
        index: null
      },
      editHour: false,
      confirmHourModal: false,
      passDay: false,
      inputs: [
        { name: 'Copropiedad o empresa', type: 'label', dataModel: 'name', iconClass: 'fas fa-user-shield', disabled: true },
        { name: 'Administrador', type: 'label', dataModel: 'admin', iconClass: 'fas fa-user', disabled: true },
        { name: 'Contacto', type: 'label', dataModel: 'contact', iconClass: 'fas fa-user', disabled: true },
        { name: 'Teléfono', type: 'label', dataModel: 'contactPhone', iconClass: 'fas fa-phone', disabled: true },
        { name: 'Fecha', type: 'label', dataModel: 'date', iconClass: 'fas fa-calendar-day', disabled: true },
        { name: 'Usuarios', type: 'label', dataModel: 'users', iconClass: 'fas fa-users', disabled: true },
        { name: 'Link Zoom', type: 'link', dataModel: 'zoomLink', iconClass: 'fas fa-video', disabled: true, message: 'Abrir Zoom' },
        { name: 'Sala', type: 'link', dataModel: 'room', iconClass: 'fas fa-link', disabled: true, message: 'Abrir Sala' },
        { name: 'Usuario Zoom', type: 'label', dataModel: 'zoomUser', iconClass: 'fas fa-link', disabled: true },
        { name: 'Direccion', type: 'label', dataModel: 'direccion', iconClass: 'fas fa-link', disabled: true }
      ],
      // La data para gestión de cuentas de cobro
      serviceList: [],
      soporteList: [
        // {
        //   title: 'Soporte telefónico',
        //   icon: 'fas fa-headset',
        //   date: '2022-02-04',
        //   space: 'mb-4',
        //   start: '06:00:00',
        //   end: '13:00:00',
        //   totalHours: 7,
        //   amount: 61000,
        //   color: 'secondary',
        //   showServices: false,
        //   details: [
        //     { title: 'CR SONATA P.H', start: '06:00:00', end: '10:00:00' },
        //     { title: 'OCLOCK', start: '08:00:00', end: '11:00:00' },
        //     { title: 'CANTARES DE RIACHUELOS', start: '07:00:00', end: '13:00:00' }
        //   ]
        // },
        // {
        //   title: 'Soporte telefónico',
        //   icon: 'fas fa-headset',
        //   date: '2022-02-04',
        //   space: 'mb-4',
        //   start: '06:00:00',
        //   end: '13:00:00',
        //   totalHours: 7,
        //   amount: 61000,
        //   color: 'secondary',
        //   showServices: false,
        //   details: [
        //     { title: 'CR SONATA P.H', start: '06:00:00', end: '10:00:00' },
        //     { title: 'OCLOCK', start: '08:00:00', end: '11:00:00' },
        //     { title: 'CANTARES DE RIACHUELOS', start: '07:00:00', end: '13:00:00' }
        //   ]
        // }
      ],
      userServices: {}
    }
  },
  mounted () {
    core.index()
    this.getAssemblies()
    // this.getWeekDates(this.weekDate)
  },
  watch: {
    rowDataCard (newValue) {
      if (newValue.length >= 1) {
        this.getWeekDates(this.weekDate)
      } else {
        this.$emit('loadSuccess', { type: false })
      }
    },
    prueba (newValue) {
      // console.log('prueba', newValue)
      if (this.rowDataCard < 1) {
        this.getWeekDates(this.weekDate)
      }
    }
  },
  computed: {
    startMonth () {
      return this.months[this.date.weekStart.month - 1]
    },
    endMonth () {
      return this.months[this.date.weekEnd.month - 1]
    },
    formatedDate () {
      return this.formatDate(this.weekDate)
    }
  },
  methods: {
    currencyFormatterCop (value) {
      return '\x24' + formatNumber(value)
    },
    resetDocument (type, kind) {
      if (kind === 'edit') {
        this[`has${type}`] = {
          status: false,
          name: '',
          url: ''
        }
      } else {
        this[`has${type}`] = this.user[`has${type}`]
      }
    },
    async saveFile (reference, name) {
      console.log('La constante files', this.$refs[reference], reference)
      try {
        const { files } = this.$refs[reference]
        this.loading = true
        console.log(files)
        const file = files[0]
        if (file) {
          const isFileType = file.type === 'application/pdf'
          console.log('file size', file.size)
          if (isFileType && file.size < 1000000) {
            const response = await firebase
              .storage()
              .ref(`/campus/Vs2FkUx38u3W8IDuABNF/contacts/${this.$route.params.idDoc}/${reference}`)
              .put(file)
            const url1 = await response.ref.getDownloadURL()
            this.user[`has${reference}`] = this[`has${reference}`] = {
              status: true,
              name: name + ' ' + this.user.name,
              url: url1
            }
            db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
              .doc(this.$route.params.idDoc)
              .update(this.user)
              .then((updatedDoc) => {
                console.log('Contacto actualizado', updatedDoc)
                db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
                  .doc(`contactsIndex${this.user.index}`)
                  .update({
                    [this.$route.params.idDoc]: this.user
                  })
                  .then((updatedIndex) => {
                    console.log('Actualizado en el Indice', updatedIndex)
                  })
              })
            // console.log('archivo disponible en ', url)
            // this.downloadUrl = url
            // this.poder.downloadUrl = url
          } else {
            this.makeToast('danger', 'El archivo excede el peso permitido. Por favor, selecciona un archivo PDF inferior a 1MB. Si requieres comprimir un documento, puedes hacerlo desde https://www.adobe.com/la/acrobat/online/compress-pdf.html')
          }
        } else {
          this.makeToast('danger', 'Archivo invalido. Por favor, selecciona un archivo PDF inferior a 1MB.')
        }
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    previewImage: function (event) {
      const input = event.target

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    async saveAvailability () {
      var availability = {}
      var prevSchedule = {}
      var internalSchedule = {
        schedule: {}
      }
      for (let index = 0; index < this.days.length; index++) {
        var variableIndex = index + 1
        if (this.dates[index].available !== null && this.dates[index].available !== undefined) {
          prevSchedule[variableIndex] = {
            available: this.dates[index].available,
            start: this.dates[index].start,
            totalServices: this.dates[index].totalServices,
            totalUsers: this.dates[index].totalUsers
          }
        }
        if (this.dates[index].available) {
          availability[index + 1] = await {
            providers: {
              [this.user.docId]: {
                docId: this.user.docId,
                name: this.user.name,
                staff: this.user.staff,
                available: this.dates[index].available,
                start: this.dates[index].start,
                totalServices: this.dates[index].totalServices,
                totalUsers: this.dates[index].totalUsers
              }
            }
          }
        }
      }
      console.log('la disponibildad', availability)
      internalSchedule.schedule[`week${this.date.weekOfYear}`] = prevSchedule
      db.collection('/campus/Vs2FkUx38u3W8IDuABNF/availableProviders')
        .doc(`week${this.date.weekOfYear}`)
        .set(availability, { merge: true })
        .then((ans) => {
          console.log(ans)
          db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/')
            .doc(`${this.$route.params.idDoc}`)
            .set(internalSchedule, { merge: true })
            .then(() => {
              console.log('I did it')
              console.log(internalSchedule)
              this.scheduleModal = false
              this.$forceUpdate()
            })
        })
    },
    providersDatesInit (weekNumber) {
      console.log('entré al init', this.user.schedule)
      if (this.user.schedule != null) {
        console.log('tiene schedule')
        if (this.user.schedule[`week${weekNumber}`] !== null && this.user.schedule[`week${weekNumber}`] !== undefined) {
          console.log('tiene semana', weekNumber)
          this.userAvailability = this.user.schedule[`week${weekNumber}`]
          console.log('disponibilidad', this.userAvailability, this.date.weekOfYear)
          for (let index = 0; index < 7; index++) {
            var variableIndex = index + 1
            this.dates.push({
              fullDate: DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c,
              date: this.days[index] + ' ' + DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day,
              start: this.userAvailability[variableIndex].start,
              available: this.userAvailability[variableIndex].available,
              editable: false,
              hasPrevValue: true,
              totalServices: this.userAvailability[variableIndex].totalServices,
              totalUsers: this.userAvailability[variableIndex].totalUsers
            })
          }
        } else {
          for (let index = 0; index < 7; index++) {
            this.dates.push({
              fullDate: DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c,
              date: this.days[index] + ' ' + DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day,
              start: '',
              available: false,
              editable: false,
              hasPrevValue: false,
              totalServices: 0,
              totalUsers: 0
            })
          }
          console.log('No tiene Semana', weekNumber)
        }
      } else {
        for (let index = 0; index < 7; index++) {
          this.dates.push({
            fullDate: DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c,
            date: this.days[index] + ' ' + DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day,
            start: '',
            available: false,
            editable: false,
            hasPrevValue: false,
            totalServices: 0,
            totalUsers: 0
          })
        }
        console.log('No tiene Schedule', weekNumber)
      }
    },
    add () {
      // const obj = this.default()
      // const ale = this.alegra()
      this.dates.push(this.default())
      // this.itemsAlegra.push(this.alegra())
    },
    edit (item) {
      console.log('estoy editando', item)
      item.editable = true
    },
    submit (item) {
      console.log('guardé', item)
      item.editable = false
      item.hasPrevValue = true
      if (item.start !== '') { item.available = true }
    },
    // remove (item) {
    //   const index = this.dates.indexOf(item)
    //   this.dates.splice(index, 1)
    // },
    async getAssemblies () {
      await db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/')
        .doc(`${this.$route.params.idDoc}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log('llené el user', doc.data().identificationObject.number)
            this.user = doc.data()
            console.log('el user', this.user.identificationObject.number)
            if (this.user.hasCV !== undefined) {
              this.hasCV = this.user.hasCV
            }
            if (this.user.hasCC !== undefined) {
              this.hasCC = this.user.hasCC
            }
            if (this.user.hasEPS !== undefined) {
              this.hasEPS = this.user.hasEPS
            }
            if (this.user.hasCP !== undefined) {
              this.hasCP = this.user.hasCP
            }
            if (this.user.hasCB !== undefined) {
              this.hasCB = this.user.hasCB
            }
            if (this.user.hasRUT !== undefined) {
              this.hasRUT = this.user.hasRUT
            }
          }
        })
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/assemblies2022Index')
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            const vm = this
            this.rowDataCard = []
            querySnapshot.forEach(doc => {
              const data = doc.data()
              this.prueba = doc.data()[180]
              Object.values(data).forEach(function (value) {
                for (let index = 0; index < vm.operatorTypes.length; index++) {
                  var currStaff = vm.operatorTypes[index]
                  var currProviders = value[currStaff]
                  if (currProviders !== undefined && currProviders.length > 0) {
                    console.log('value en ', currStaff, currProviders, value.id)
                    for (let index2 = 0; index2 < currProviders.length; index2++) {
                      console.log('en ', currStaff, currProviders[index2], vm.user.name)
                      if (currProviders[index2].docId === vm.user.docId) {
                        vm.rowDataCard.push({
                          id: value.id,
                          name: value.name,
                          paymentStatus: value.paymentStatus,
                          date: value.date,
                          hour: value.hour,
                          endHour: value.endHour,
                          endDate: value.endDate,
                          canEditHour: {
                            docId: vm.$route.params.idDoc,
                            canEdit: true
                          },
                          users: value.users,
                          admin: value.admin,
                          contact: value.nombreContacto,
                          contactPhone: value.celularContacto,
                          boardId: value.boardId,
                          type: value.modalidad,
                          operarioActaSeleccionado: value.operarioActaSeleccionado,
                          operarioConexionSeleccionado: value.operarioConexionSeleccionado,
                          operarioRegistroSeleccionado: value.operarioRegistroSeleccionado,
                          operarioSonidoSeleccionado: value.operarioSonidoSeleccionado,
                          operarioSoporteSeleccionado: value.operarioSoporteSeleccionado,
                          operarioVirtualSeleccionado: value.operarioVirtualSeleccionado,
                          zoomLink: value.linkReunion,
                          zoomUser: value.usuarioZoom,
                          room: value.sala,
                          index: value.index,
                          direccion: value.direccion
                        })
                        console.log('Este está', vm.user.name, value)
                      }
                    }
                  }
                }
              })
            })
          }
        })
    },
    formatAMPM (d) {
      var strTime = d
      if (d !== '-') {
        const hour = d.split(':')
        var hours = hour[0]
        var minutes = hour[1]
        var ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours === 0 ? 12 : hours // the hour '0' should be '12'
        hours = hours < 10 ? '0' + hours : hours
        strTime = hours + ':' + minutes + ' ' + ampm
      }
      return strTime
    },
    async getWeekDates (myDate) {
      for (let index2 = 0; index2 < this.boards.length; index2++) {
        this.boards[index2].list = await []
        this.boards[index2].available = {
          presencial: 10,
          virtual: 20,
          mixta: 10,
          cotizacion: 0
        }
        this.dates = []
      }
      this.date.today.day = DateTime.now.day
      this.date.today.month = DateTime.now.month
      this.date.today.year = DateTime.now.year
      this.date.weekStart = await DateTime.local(myDate.year, myDate.month, myDate.day).startOf('week').c
      this.date.weekEnd = DateTime.local(myDate.year, myDate.month, myDate.day).endOf('week').c
      this.date.weekOfYear = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).weekNumber
      for (let index = 0; index < this.boards.length; index++) {
        this.boards[index].date = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day
      }
      if (this.rowDataCard !== null) { this.getCardsInfo() }
      this.providersDatesInit(this.date.weekOfYear)
    },
    getCardsInfo () {
      // console.log('trayendo los datos', this.rowDataCard.length)
      for (let index = 0; index < this.rowDataCard.length; index++) {
        var splitedDate
        if (this.rowDataCard[index].date !== undefined || this.rowDataCard[index].date !== null) {
          splitedDate = this.rowDataCard[index].date.split('-')
        }
        const year = parseFloat(splitedDate[0])
        const month = parseFloat(splitedDate[1])
        const day = parseFloat(splitedDate[2])
        const cardDay = DateTime.local(year, month, day).ordinal
        const startDate = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).ordinal
        const endDate = DateTime.local(this.date.weekEnd.year, this.date.weekEnd.month, this.date.weekEnd.day).ordinal
        if (cardDay >= startDate && cardDay <= endDate) {
          // console.log('Its between')
          for (let index2 = 0; index2 < this.boards.length; index2++) {
            if (this.boards[index2].id === this.rowDataCard[index].boardId) {
              // console.log('Is ths board', this.boards[index2].id)
              const filters = this.filters
              if (filters[this.rowDataCard[index].type] === true) {
                const typeOf = this.rowDataCard[index].type
                if (typeOf === 'virtual') {
                  this.boards[index2].available[typeOf] -= 1
                }
                if (typeOf === 'presencial') {
                  this.boards[index2].available.presencial -= 1
                }
                if (typeOf === 'mixta') {
                  this.boards[index2].available.presencial -= 1
                  this.boards[index2].available.virtual -= 1
                }
                if (typeOf === 'cotizacion') {
                  this.boards[index2].available.cotizacion += 1
                }
                // console.log('antes de', this.boards[index2].available[typeOf])
                // console.log('despues de', this.boards[index2].available[typeOf])
                this.boards[index2].list.push(this.rowDataCard[index])
              }
            }
          }
          this.$emit('loadSuccess', { type: false })
        } else { this.$emit('loadSuccess', { type: false }) }
      }
    },
    async changeWeek (type) {
      for (let index2 = 0; index2 < this.boards.length; index2++) {
        this.boards[index2].list = await []
        this.boards[index2].available = {
          presencial: 10,
          virtual: 20,
          mixta: 10,
          cotizacion: 0

        }
      }
      console.log('listas vacias')
      if (type === 'next') {
        this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).plus({ days: 7 }).c
        console.log('add', this.weekDate)
        this.getWeekDates(this.weekDate)
      }
      if (type === 'prev') {
        this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).minus({ days: 7 }).c
        this.getWeekDates(this.weekDate)
        console.log('minus', this.weekDate)
      }
    },
    async filterAssemblies () {
      for (let index2 = 0; index2 < this.boards.length; index2++) {
        this.boards[index2].list = await []
        this.boards[index2].available = {
          presencial: 10,
          virtual: 20,
          mixta: 10,
          cotizacion: 0
        }
      }
    },
    operateDates (type, quantity) {
      if (type === 'adition') {
        return DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: quantity }).c.day
      }
      if (type === 'sustraction') {
        return DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).minus({ days: quantity }).c.day
      }
    },
    makeToast (variant = null, message) {
      var title1 = ''
      if (variant === 'success') {
        title1 = 'Proceso exitoso'
      } else if (variant === 'danger') {
        title1 = 'Proceso fallido'
      }
      this.$bvToast.toast(message, {
        title: title1,
        variant: variant,
        solid: true,
        autoHideDelay: 10000
      })
    },
    // Métodos para ver info de la asamblea
    viewAssemblieInfo (assemblie) {
      console.log(assemblie)
      this.currentAssemblie = assemblie
      for (let index = 0; index < this.operatorTypes.length; index++) {
        var serviceType = this.operatorTypes[index]
        var currOperators = this.currentAssemblie[serviceType]
        console.log(currOperators)
        if (currOperators.length > 0) {
          for (let index2 = 0; index2 < currOperators.length; index2++) {
            if (this.user.docId === currOperators[index2].docId) {
              this.currentService = {
                data: currOperators[index2],
                type: serviceType,
                index: index2
              }
              this.currentList = currOperators
            }
          }
        }
      }
      this.assemblieInfo = true
    },
    clear () {
      this.currentAssemblie = {
        canEditHour: {
          canEdit: false
        }
      }
      this.currentList = {}
      this.assemblieInfo = false
    },
    async checkHours () {
      console.log('checking hours')
      const date = this.currentAssemblie.date.split('-')
      var [year, month, day] = date
      if (this.currentAssemblie.endHour < this.currentAssemblie.hour) {
        console.log(this.currentAssemblie.endHour, ' es menor que', this.currentAssemblie.hour)
        const rawEndDate = DateTime.local(parseInt(year), parseInt(month), parseInt(day)).plus({ days: 1 }).c
        this.currentAssemblie.endDate = await rawEndDate.year + '-' + rawEndDate.month + '-' + rawEndDate.day
        this.passDay = true
      } else {
        console.log(this.currentAssemblie.endHour, ' es mayor que', this.currentAssemblie.hour)
        this.currentAssemblie.endDate = await this.currentAssemblie.date
      }
      this.confirmHourModal = true
    },
    async updateAssemblie () {
      var index
      this.currentList[this.currentService.index] = this.currentService.data
      if (this.currentAssemblie.index === null || this.currentAssemblie.index === undefined) {
        console.log('es indefinido')
        index = await 1
      } else {
        console.log('es definido', this.currentAssemblie.index)
        index = await this.currentAssemblie.index
      }
      this.confirmHourModal = false
      this.assemblieOverlay = true
      var serviceType = this.currentService.type
      const toUpdate = {
        [this.currentAssemblie.id]: {
          [serviceType]: this.currentList
        }
      }
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/assemblies2022Index/')
        .doc(`assembliesIndex${index}`)
        .set(toUpdate, { merge: true })
        .then(() => {
          this.assemblieOverlay = false
          this.assemblieInfo = false
          this.currentAssemblie.canEditHour.canEdit = false
          console.log('Actualizado', toUpdate)
        }).catch((error) => {
          console.error('Error writing document: ', error)
          this.assemblieOverlay = false
        })
    },
    async getServices () {
      const vm = this
      console.log('Trayendo los servicios')
      await db.collection('campus/Vs2FkUx38u3W8IDuABNF/services')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              var currDoc = doc.data()
              Object.keys(currDoc).forEach(function (key) {
                var currKey = key.split('-')
                var [assemblieId, userId] = currKey
                if (userId === vm.user.docId) {
                  vm.userServices[assemblieId] = currDoc[key]
                }
              })
            })
          }
        })
      this.fillServiceList()
    },
    fillServiceList () {
      this.serviceList = []
      this.soporteList = []
      const vm = this
      Object.values(vm.userServices).forEach(function (value) {
        var type = value.type
        var newService = {}
        var newSupport = {}
        if (type === 'soporte') {
          console.log('Es soporte')
          newSupport.type = type
          newSupport.title = value.name
          newSupport.space = 'mb-4'
          newSupport.totalHours = value.totalHours
          newSupport.amount = value.value
          newSupport.color = 'secondary'
          newSupport.date = value.date
          newSupport.start = value.hour
          newSupport.end = value.endHour
          newSupport.status = value.status
          newSupport.icon = 'fas fa-chalkboard-teacher'
          vm.soporteList.push(newSupport)
        } else {
          newService.type = type
          newService.title = value.name
          newService.space = 'mb-4'
          newService.totalHours = value.totalHours
          newService.amount = value.value
          newService.color = 'secondary'
          newService.date = value.date
          newService.start = value.hour
          newService.end = value.endHour
          newService.status = value.status
          switch (type) {
            case 'virtual':
              newService.icon = 'fas fa-chalkboard-teacher'
              break
            case 'sonido':
              newService.icon = 'fas fa-volume-up'
              break
            case 'registo':
              newService.icon = 'fas fa-file-signature'
              break
            case 'liderAsamblea':
              newService.icon = 'fas fa-user-plus'
              break
            case 'ayudanteRegistro':
              newService.icon = 'fas fa-user-minus'
              break
            default:
              newService.icon = 'fas fa-question'
          }
          vm.serviceList.push(newService)
        }
      })
    }
    // checkForSupports () {
    //   for (let index = 0; index < this.soporteList.length; index++) {
    //     const element = array[index];
    //   }
    // }
  }
}
</script>
<style scoped>
input.custom-fi{
  line-height: 20px!important;
}
.hoverSpan {
  cursor: pointer;
}
.bullet-list {
  list-style: disc!important;
}
</style>
